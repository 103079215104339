import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SouhaiteComponent } from './souhaite.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatListModule, MatDividerModule, MatButtonModule, MatSnackBarModule, MatExpansionModule } from '@angular/material';

const routes = [
  {
      path     : 'simulateurs/volume-souhaite',
      component: SouhaiteComponent
  }
];


@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,

    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),

    MatInputModule,
    MatListModule,
    MatDividerModule,
    MatButtonModule,
    MatSnackBarModule,
    MatExpansionModule
  ],
  declarations: [SouhaiteComponent],
  exports: [SouhaiteComponent]
})
export class SouhaiteModule { }
