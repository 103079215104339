import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptableComponent } from './captable.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';

const routes = [
  {
      path     : 'simulateurs/volume-captable',
      component: CaptableComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule
  ],
  declarations: [CaptableComponent],
  exports: [CaptableComponent]
})
export class CaptableModule { }
