import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'fuse-souhaite',
  templateUrl: './souhaite.component.html',
  styleUrls: ['./souhaite.component.scss']
})
export class SouhaiteComponent implements OnInit {
  
  step = 0;
  result = null;
  closestN1 = {};
  closestN2 = {};
  closestN3 = {};

  constructor() { }

  inputFormControl = new FormControl('', [
    Validators.compose([Validators.min(600)])
  ]);

  ngOnInit() {
    this.inputFormControl.setValue(600);
  }

  simulate() {
    this.result = null;

    const n1 = [];
    const n2 = [];
    const n3 = [];

    const that = this;

    for (let i = 1; i <= 100; i++) {
      n1.push(this.simulateByRowsAndColumns(1, i, 1));
    }

    for (let i = 1; i <= 100; i++) {
      n2.push(this.simulateByRowsAndColumns(2, i, 1));
    }

    for (let i = 1; i <= 100; i++) {
      n3.push(this.simulateByRowsAndColumns(3, i, 1));
    }

    this.closestN1 = n1.reduce(function(prev, curr) {
      return (Math.abs(curr.capacity - that.inputFormControl.value) < Math.abs(prev.capacity - that.inputFormControl.value) ? curr : prev);
    });

    this.closestN2 = n2.reduce(function(prev, curr) {
      return (Math.abs(curr.capacity - that.inputFormControl.value) < Math.abs(prev.capacity - that.inputFormControl.value) ? curr : prev);
    });

    this.closestN3 = n3.reduce(function(prev, curr) {
      return (Math.abs(curr.capacity - that.inputFormControl.value) < Math.abs(prev.capacity - that.inputFormControl.value) ? curr : prev);
    });

    this.result = [this.closestN1, this.closestN2, this.closestN3];
    this.result === null ? this.nextStep() : this.setStep(1);

  }

  simulateByRowsAndColumns(rows: number, columns: number, juxt: number) {
    return {
        capacity: (rows * columns * 500) + (columns * 100) * juxt,
        height: (rows - 1) * 1220 + 1460,
        width: columns * 1210,
        depth: 410 * juxt,
        weight: (rows * columns * 45) + (columns * 13) * juxt,
        tank: columns * rows * juxt,
        base: columns * juxt,
        link: '/simulateurs/configuration/' + rows + '/' + columns + '/' + juxt + '/0/0/0/0/0'
    };
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
      this.step++;
  }

  prevStep() {
      this.step--;
  }

}
