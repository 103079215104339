import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import { unescape, escape } from 'lodash';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'fuse-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent {
    step = 0;
    data = {};
    link = '';
    premount = false;
    potable = false;
    hasError = false;
    result = null;
    previewFace = null;
    previewSide = null;

    constructor(public sb: MatSnackBar, public dialog: MatDialog, private cb: ClipboardService)
    {
        if (location.pathname !== '/simulateurs/configuration') {
            const data = location.pathname.split('/');
            this.rowsFormControl.setValue(+data[3]);
            this.columnsFormControl.setValue(+data[4]);
            this.juxtFormControl.setValue(+data[5]);
            this.colgFormControl.setValue(+data[6]);
            this.robsFormControl.setValue(+data[7]);
            this.cptFormControl.setValue(+data[8]);
            +data[9] === 0 ? this.premount = false : this.premount = true;
            +data[10] === 0 ? this.potable = false : this.potable = true;

            this.simulate();
            history.pushState({}, null, location.origin + '/simulateurs/configuration');
        }
        else {
            this.rowsFormControl.setValue(1);
            this.columnsFormControl.setValue(1);
            this.juxtFormControl.setValue(1);
            this.colgFormControl.setValue(0);
            this.robsFormControl.setValue(0);
            this.cptFormControl.setValue(0);
            this.premount = false;
            this.potable = false;
        }
    }

    rowsFormControl = new FormControl('', [
        Validators.compose([Validators.required, Validators.min(1), Validators.max(3)])
    ]);

    columnsFormControl = new FormControl('', [
        Validators.compose([Validators.required, Validators.min(1)])
    ]);

    juxtFormControl = new FormControl('', [
        Validators.compose([Validators.required, Validators.min(1)])
    ]);

    colgFormControl = new FormControl('', [
        Validators.compose([Validators.min(0)])
    ]);

    robsFormControl = new FormControl('', [
        Validators.compose([Validators.min(0)])
    ]);

    cptFormControl = new FormControl('', [
        Validators.compose([Validators.min(0)])
    ]);

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

   
    simulate() {
        this.result = null;
        this.previewFace = {};
        this.previewSide = {};
        this.hasError = false;

        this.data = {
            rows: this.rowsFormControl.errors       || this.rowsFormControl.value,
            columns: this.columnsFormControl.errors || this.columnsFormControl.value,
            juxt: this.juxtFormControl.errors       || this.juxtFormControl.value,
            colg: this.colgFormControl.errors       || this.colgFormControl.value,
            robs: this.robsFormControl.errors       || this.robsFormControl.value,
            cpt: this.cptFormControl.errors         || this.cptFormControl.value
        };

        if (this.data['colg'] === '') {
            this.data['colg'] = 0;
        }

        if (this.data['robs'] === '') {
            this.data['robs'] = 0;
        }

        if (this.data['cpt'] === '') {
            this.data['cpt'] = 0;
        }

        for (let i in this.data) {
            if (typeof this.data[i] === 'object' || this.data[i] === null) {
                this.sb.open('Le formulaire comporte des erreurs', null, {duration: 2000});
                this.hasError = true;
                break;
            }
        }

        if (!this.hasError) {
            this.result = this.simulateByRowsAndColumns(this.data['rows'], this.data['columns'], this.data['juxt'], this.data['colg'], this.data['robs']);
            this.buildPreview(this.data['rows'], this.data['columns'], this.data['juxt']);
            this.generateLink();
            this.result === null ? this.nextStep() : this.setStep(2);

        }
    }

    simulateByRowsAndColumns(rows: number, columns: number, juxt: number, colg: number, robs: number) {
        return {
            capacity: ((rows * columns * 500) + (columns * 100)) * juxt,
            height: (rows - 1) * 1220 + 1460,
            width: columns * 1210,
            depth: 410 * juxt,
            weight: ((rows * columns * 45) + (columns * 13)) * juxt,
            tank: columns * rows * juxt,
            base: columns * juxt,
            colg: colg,
            dn125: columns * rows * juxt,
            dn50: ((columns * juxt) - 1) * 2 + colg,
            security: columns * juxt,
            drain: 1,
            grille: columns * juxt,
            tap: 1 + robs,
            screws: 4 * columns * rows * juxt
        };
    }

    buildPreview(rows: number, columns: number, juxt: number) {
        this.previewFace = buildFace(rows, columns, juxt); 
        this.previewSide = buildSide(rows, columns, juxt);
    }

    generateLink() {
        this.link = location.host + '/simulateurs/configuration';

        let tmpPremount;
        let tmpPotable;
        this.premount ? tmpPremount = 1 : tmpPremount = 0;
        this.potable ? tmpPotable = 1 : tmpPotable = 0;
        // tslint:disable-next-line:max-line-length
        // let params = `?rows=${this.data.rows}?cols=${this.data.columns}?juxt=${this.data.juxt}?colg=${this.data.colg}?robs=${this.data.robs}?kits=${this.cptFormControl.value}?premount=${this.premount}`;
        const newData: any = this.data;
        const params = `/${newData.rows}/${newData.columns}/${newData.juxt}/${newData.colg}/${newData.robs}/${newData.cpt}/${tmpPremount}/${tmpPotable}`;
        this.link += params;
    }

    copyToClipboard() {
        this.cb.copyFromContent(this.link);
        this.sb.open('Lien copié !', null, {duration: 2000});
    }


}

function buildFace(rows: number, columns: number, juxt: number) {
    let tmp = [];

    for (let i = 1; i <= rows; i++) {
        const row = [];
        for (let j = 1; j <= columns; j++) {
            if (i !== rows) {
                row.push(1);
            }
            else {
                row.push(2);
            }
        }
        tmp.push(row);
    }

    return tmp;
    
}

function buildSide(rows: number, columns: number, juxt: number) {
    let tmp = [];

    for (let k = 1; k <= rows; k++) {
        const row = [];
        for (let l = 1; l <= juxt; l++) {
            if (k !== rows) {
                row.push(1);
            }
            else {
                row.push(2);
            }
        }
        tmp.push(row);
    }

    return tmp;

}

function utf8_to_b64( str ) {
    return window.btoa(unescape(encodeURIComponent(str)));
}
  
function b64_to_utf8( str ) {
    return decodeURIComponent(escape(window.atob(str)));
}
